import { useNavigate } from "react-router-dom";
import useFectch from "../../utility/getFunction";
import { GiShoppingCart } from "react-icons/gi";
import { FaListUl, FaSearch } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { orderLocationId, orderMenuId } from "../../utility/axios";



const SearchBar =(()=>{

    const [subCatName, setSubCatName] = useState('');
    const [displayNoEnter, setDisplayNoEnter]= useState(false);

    const [displayDropCat, setDisplayDropCat]= useState(false);

    const navigate = useNavigate();

	const navigateShoppingCart = (()=>{
		navigate("/shopping-cart")
	})

  const {
    data: productsItem,
    isPending: productsIsPending,
    error: productsError
  } = useFectch(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`);

  const [allCategories, setAllCategories] = useState(true)

//   const {
//     data: productsCategory,
//     isPending: productsIsPendingCategory,
//     error: productsErrorCategory
//   } = useFectch('/generals/getAllCategories');

const totalCount = useSelector((state) => {

  return state.cartProduct.productItems.length

})

const handleDisplayCat = ()=>{
  setDisplayDropCat(!displayDropCat)
}

    // const productData = productsCategory.filter((pro)=>(
    //       pro.categoryName !== "Premium/Value Added Services" && !pro.categoryName !== "Data"
	// 	   && !pro.categoryName !== "Credit Vouchers" && !pro.categoryName !== "MSISDN Numbers" 
	// 	    && !pro.categoryName !== "SIM Cards" 
	// ))

// const filterCategories = (()=>{

// })
const subCatogoryFilter = productsItem.products && productsItem.products.flatMap((products) => (
          products.productDetailImages

  ))

  const subCategoryImages = productsItem.products && productsItem.products
  .filter(product => product.subCategoryName && product.subCategoryName.length > 0)
  .flatMap(product => product.subCategoryName);

  const filteredProducts = subCatogoryFilter && subCatogoryFilter.filter(product => 
    product.subCategoryName != "Data" && product.subCategoryName != "Credit Vouchers"
  );

console.log("subCategoryImagesWITHOUTDATA>>>>", filteredProducts)

  const [display, setDisplay] = useState(false)

  const handleDisplay = (() => {
    setDisplay(true)
  })

  const [search, setSearch] = useState("")

  const handleDirect = (() => {

    setDisplay(false)
  })
 

  const handleSearchDisplay = ((event) => {
    setSearch(event.target.value)
    console.log("handleSearchDisplay", search)
  })

  const filterCategory = productsItem.products && productsItem.products.filter(product =>
    product.subCategoryName != "Data" && product.subCategoryName != "Credit Vouchers"
  )

  
  // const userData = JSON.parse(localStorage.getItem("ecomUserData"));

  let locationList1 = useRef();

useEffect(() => {
    const handler = (event) => {
      if (locationList1.current && !locationList1.current.contains(event.target)) {
        locationList1.current.style.display = 'none';
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.addEventListener("click", handler);
    };
  }, [locationList1]);

  
  const handleNavigateDetails = ((productData)=>{
    if(productData == 0){
      setDisplayNoEnter(true)
    }else{
      setDisplayNoEnter(false)
      navigate('/e-commerce', { state: { productData: productData } });
      
    }
  

  })

  const handleAllSubCat = (()=>{
    // setAllCategories(filterCategory)
    navigate('/e-commerce');
    })

  const handleSubCatName = (categories) => {
    navigate('/categories', { state: { categories: categories } });
 

  }
  const uniqueCategoriesMap = new Map();

// Iterate over the data and add the first product of each category to the map
filteredProducts && filteredProducts.forEach(item => {
if (!uniqueCategoriesMap.has(item.categoryId)) {
uniqueCategoriesMap.set(item.categoryId, item);
}
});

// Convert the map values to an array
const uniqueCategories = Array.from(uniqueCategoriesMap.values());

  console.log("uniqueCategories", uniqueCategories)

    return(


        <>
        <div className="jx-send">
        <div className="categoryDrop">
				<ul className="list_mainName-ul">
              <li className="list_mainName" onClick={handleDisplayCat}> <span className="mainNamen-all">All Categories </span>   <span className="mainNamen" > <FaListUl className="categoryIcon"/></span>
               {
                displayDropCat &&  <li className="listS-categories" >
                <li onClick={handleAllSubCat}> <i> <FaListUl/> </i> All </li>
                    {
                   uniqueCategories && uniqueCategories.map((product)=>(
                   <li key={product.categoryId} 
                   onClick={() => handleSubCatName(product.categoryId)}> 
                  <img className="listApp-mg" src={product?.displayImage}/>   
                      <span className="listApp-mgTExt">{product.categoryName} </span> 
             
             </li> 
                 ))
               }
                
               </li>
               }
              
              
			    
			     
			  </li>
				
				</ul>
			  
			 </div>
			<div>
			<form className="search-container">
            <input type="text" id="search-bar" placeholder="Search Vitel Products, Brands" 
            onChange={handleSearchDisplay}
            
            />
              <a className="search-icon" onClick={()=>handleNavigateDetails(search)}><FaSearch size={20}/></a>
               
            {/* <div ref={locationList1} className="allsearch-drop">
              jjjjjjjjjjjjjjjjj */}
              {
                displayNoEnter && <p> Search field cannot be empty </p>
              }
                              {/* {
                filteredProducts && filteredProducts.slice(0, 10).filter((product) => (
                  product.productName.toLowerCase().indexOf(search) !== -1

                )).map ((name) => (

                  <ul className="ulcontrol-search">

                    <div  onClick={(() => handleNavigateDetails(name))} className="linktodisplay-search">
                      <li><i><img className="picSearch-control" src={name.displayImage} /> </i>
                        <span> {name.productName} </span>
                      </li>
                    </div>
                  </ul>

                ))
              } */}



            {/* </div> */}
           </form>
			</div>
			<div className="cart-Mycart-div">
		     <spa className="cart-Mycart-span" onClick={navigateShoppingCart}>

				<i className="cartIicon"><GiShoppingCart size={22}/>  </i> <span className="cart-Mycart">My Cart</span>
				 <span className="num-Cart"> {totalCount} </span> 
			</spa>
			</div>
          
	      </div>


    {/* /////////////////////////////////////////////////////////*  */} 

      

        
        </>
    )
})

export default SearchBar