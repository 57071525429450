import React, { useEffect, useState } from 'react';
import './ProductDetailsPage.css';
import { GrDeliver } from 'react-icons/gr';
import { GiCardPickup, GiShoppingCart } from 'react-icons/gi';
// import { BsCart4 } from "react-icons/bs";
import SearchBar from '../EcommerceComponent/searchBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartProduct, clearCartProduct, decrease, increase } from '../../redux/productCounter';
import { Button, Modal } from 'react-bootstrap';
import { IoIosReturnLeft } from 'react-icons/io';
import { PaystackButton } from 'react-paystack';
import { FaHome } from 'react-icons/fa';
import ProductsCart from '../ProductsCartComponent/ProductsCart';
import useFectch from '../../utility/getFunction';
import { orderLocationId, orderMenuId } from '../../utility/axios';

const ProductDetailsPage = () => {
  const [login, setLogin] = useState(false);
  const [productChange, setProductChange] = useState("");

  const location = useLocation();
  const { product } = location.state || {};

  const dispatch = useDispatch();

  const { productItems } = useSelector(state => state.cartProduct);

  const [referenceCode, setReferenceCode] = useState('');

  const [item, setItem] = useState('');
  const [showAdded, setShowAdded] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    document.title = product ? `Vitel Wireless | Product Details - ${product.productName}` : 'Product Details';
  }, [product]);

  const defaultEmail = 'chuks1243@gmail.com';

  const handleDataProduct = pro => {
    setShowAdded(true);
    scrolltop();
    dispatch(addToCartProduct(pro));
    setItem(pro);

    setTimeout(() => {
      setShowAdded(false);
    }, 3000);
  };
  const navigate = useNavigate();

  const navigateCartPage = () => {
    navigate('/shopping-cart');
  };

  const navigateEcommerce = () => {
    navigate('/e-commerce');
  };

  useEffect(()=>{
    setProductChange(product)

  },[product]);

  const handlePaystackSuccessAction = reference => {
    setReferenceCode(reference.reference);
    dispatch(clearCartProduct());
    setModalShow(true);
  };
  const handlePaystackCloseAction = () => {
    navigate('/shopping-cart');
  };

  const afterPaymentNavigateECommerce = () => {
    navigate('/e-commerce');
  };
  const afterPaymentHome = () => {
    navigate('/');
  };

  const navigateHome = () => {
    navigate('/');
  };

  const componentProps = {
    email: defaultEmail,
    amount: product?.pricePerItem * 100,

    // metadata: {

    //   fullname: firstName,
    //   phone: phoneNumber,
    //   additionNumber,
    //   deliveryAddress,
    //   addtionInfo,
    //   state,
    //   city
    // },

    publicKey: 'pk_test_7c1eddc66acb8a19f5d6ea061840ce18005d7ddc',
    text: 'Pay Now',
    onSuccess: reference => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const itemsCount = productItems.length;

  console.log('logitem>>', itemsCount);

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);

  console.log('productItems', productItems);

  const navigateShoppingCart = () => {
    navigate('/shopping-cart');
  };

  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));

  const handleProceedDelivery = () => {
    if (subscribersData) {
      navigate('/delivery-pickup');
    } else {
      navigate('/subscriber-login');
    }
  };

  const navigateAllProduct = () => {
    navigate('/e-commerce');
  };

  const totalPrice = productItems.reduce((acc, item) => {
    return acc + Number(item.pricePerItem) * item.quantity;
  }, 0);

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');
  const [checkQuantity, setCheckQuantity] = useState('');

  const {
    data: productsData,
    isPending: productsIsPending,
    error: productsError,
  } = useFectch(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`);

  const matchingProducts =
    productsData.products &&
    productsData.products.filter(product => {
      return product.comboId === product.comboId;
    });

  const numChecker = parseInt(checkQuantity[0], 10);

  const handleChangeProduct = ((pro)=>{
     console.log("proCheck", pro);
     setProductChange(pro); 
     scrolltop();
     
    });

  // const testNow = matchingProducts

  // useEffect(()=>{
  //  },[checkQuantity])

  const matchData = matchingProducts && matchingProducts.filter(data => productChange.comboId === data.comboId);
  const relatedPro = matchingProducts && matchingProducts.flatMap(data => data.productDetailImages);

  const matchDataRelated = relatedPro && relatedPro.filter(data => data.categoryName === productChange.categoryName);

  const DataRelatedRemovePresent = matchDataRelated && matchDataRelated.filter(data => data.comboId != productChange.comboId);

  console.log('numChecker', productChange);
  console.log('matchDataRelated>>>>>===', matchDataRelated);

  // useEffect(()=>{
  //   setCheckQuantity(matchData && matchData.map(item => item.fields?.[0]?.fieldItems?.[0]?.currentQuantity))

  // },[])

  return (
    <div className="parentProductDetailsPage">
      <SearchBar />

      <div className="bredMove">
        <nav className="breadcrumbs">
          <a className="breadcrumbs__item" onClick={navigateHome}>
            Home
          </a>
          <a className="breadcrumbs__item " onClick={navigateAllProduct}>
            All Products
          </a>
          <a className="breadcrumbs__item  is-active">Product-details</a>
          {/* <a className="breadcrumbs__item">Cart</a>
      <a className="breadcrumbs__item ">Checkout</a>  */}
        </nav>
      </div>

      {showAdded && (
        <div className="imgMOdalDiv">
          <div className="dtailDiv1">
            <div className="dtailDiv1-inside">
              <div>
                {' '}
                <img className="imgMOdal" src={item?.displayImage} />{' '}
              </div>
              <div>
                {' '}
                <span className="itName">{item?.productName} </span>
                <br />
                has been added to your cart
              </div>
            </div>
          </div>

          <div className="dtailDiv2">
            <div className="hmCSubtotal">
              Cart Subtotal: {symbol}
              {totalPrice}
            </div>
            <div>
              <button className="button-Upper" role="button" onClick={handleProceedDelivery}>
                Proceed to checkout ({itemsCount} items)
              </button>
            </div>

            <div>
              <button className="button-13 mt-2" role="button" onClick={navigateCartPage}>
                Go to Cart
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="productDetailsPage">
        <div className="productDeilsImgDiv">
          <div className="productDetIMG">
            <img src={productChange?.displayImage} alt="" />
          </div>
          <div className="productOtherIMG">
            <div className="image1">
              <img src={productChange?.displayImage} alt="" />
            </div>
            <div className="image1">
              <img src={productChange?.displayImage} alt="" />
            </div>
            <div className="image1">
              <img src={productChange?.displayImage} alt="" />
            </div>
          </div>
        </div>
        <div className="productDetailsDescripDiv">
          <div className="productdetName">
            <div className="productDetHeadPart">
              <div>
                <h3>{productChange?.productName}</h3>
                <span className="productNameSpan">
                  {' '}
                  &#8358;{' '}
                  {parseInt(productChange?.pricePerItem).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
              <div className="buyItemDivProductDes">
                <button
                  className="planAddTOCart2 btn btn-primary"
                  onClick={() => handleDataProduct(productChange)}
                  disabled={productsIsPending || matchData?.[0]?.fields?.[0]?.fieldItems?.[0]?.currentQuantity === 0}
                >
                  <GiShoppingCart className="cartIcon" />
                  Add to cart
                </button>

                {matchData?.[0]?.fields?.[0]?.fieldItems?.[0]?.currentQuantity === 0 ? (
                  <span className="display-Outofstock"></span>
                ) : null}

                {/* {login ? (
                  <PaystackButton {...componentProps} className='planBuyNow2' />
                   ) : (
                  <button className='planBuyNow2' onClick={navigateLogin}>
                    Pay Now
                  </button>
                )} */}
                {/* )} */}
              </div>
            </div>

            <p>{productChange?.longDescription}</p>
            {/* <h4>Short Description</h4>
            <div className='promotionalOffer'>{product?.shortDescription}</div> */}

            {/* <div className='quantityDiv'>
              <div className='quantityLabel'>&#8358; 1,200.00</div>

              <span className='quantityBtnDiv'>
                {" "}
                {product?.currentDiscount}%{" "}
              </span>
            </div> */}
          </div>
        </div>
        <div className="productDeliveryDiv">
          <ProductsCart productInCart={productItems} />

        </div>
      </div>


      <div className='product-containerAll'>
        <h4 className='youmay-Like'>You may also like</h4>

        <div class="product-container-Like">

          {
            DataRelatedRemovePresent?.length != 0 ?
              DataRelatedRemovePresent && DataRelatedRemovePresent.map((pro) => (
                <div class="product-card-like" >
                  <img src={pro.displayImage} className="product-image"  onClick={()=>handleChangeProduct(pro)}/>
                  {/* {
                    matchData?.[0]?.fields?.[0]?.fieldItems?.[0]?.currentQuantity === 0 ? (
                      <span className='stockDesing-ch'>Out of Stock</span>
                  ) : (
                        null
                  )
                  } */}
                  <h3 class="product-name">{pro.productName?.slice(0, 15)}</h3>
                  <p class="product-description">{pro.shortDescription?.slice(0, 25)}</p>
                  <div class="product-price">{symbol}{pro.pricePerItem}</div>
                  <button class="add-to-cart-button" onClick={() => handleDataProduct(product)}
                    disabled={productsIsPending || matchData?.[0]?.fields?.[0]?.fieldItems?.[0]?.currentQuantity === 0}>Add to Cart</button>
                </div>

              )) : <h5 className='p-5'> Currently, there are no related products available. Please check back later. </h5>
          }






          {/* <div class="product-card-like">
  <img src="https://images.unsplash.com/photo-1546868871-7041f2a55e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"  className="product-image"/>
  <h3 class="product-name">Product Name</h3>
  <p class="product-description">Product Description</p>
  <div class="product-price">
    <span class="original-price">N2000</span>
    <span class="discount-price">N2000</span>
  </div>
  <button class="add-to-cart-button">Add to Cart</button>
</div>
  
  
  
  
  
  
  <div class="product-card-like">
  <img src="https://images.unsplash.com/photo-1583394838336-acd977736f90?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=384&q=80"  className="product-image"/>
  <h3 class="product-name">Product Name</h3>
  <p class="product-description">Product Description</p>
  <div class="product-price">N2000</div>
  <button class="add-to-cart-button">Add to Cart</button>
</div>
 
  
  
  
    
  
  <div class="product-card-like">
  <img src="https://images.unsplash.com/photo-1511499767150-a48a237f0083?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80" className="product-image"/>
  <h3 class="product-name">Product Name</h3>
  <p class="product-description">Product Description</p>
 <div class="product-price">
    <span class="original-price">N2000</span>
    <span class="discount-price">N2000</span>
  </div>  <button class="add-to-cart-button">Add to Cart</button>
</div>
 
   */}


        </div>


      </div>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="proDisplayforcart">Product successfully added to your cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="imgMOdalDiv">
            <div className="imtDiv">
              <div>
                {' '}
                <img className="imgMOdal" src={item?.displayImage} />{' '}
              </div>
            </div>

            <div>
              {' '}
              <span className="itName">{item?.productName} </span> has been added to your cart
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={navigateEcommerce}>
            <IoIosReturnLeft /> Continue shopping
          </Button>
          <Button className="aCartMpro" onClick={navigateCartPage}>
            <GiShoppingCart /> Check cart
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: 'green' }}>
            Order Completed Succcesfully
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>
          <p>You have completed your order, you can purchase more durable and affordable products from us,</p>
          <p>Thanks for your patronage, your goods will be delivered soon!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={navigateEcommerce}>
            <IoIosReturnLeft /> Continue shopping
          </Button>
          <Button onClick={afterPaymentHome} className="aCartMpro">
            <FaHome /> Go Home
          </Button>
        </Modal.Footer>
      </Modal>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default ProductDetailsPage;
