import { useNavigate } from 'react-router-dom';
import './moreVas.css';

const ViewMoreVas = () => {
  const navigate = useNavigate();

  const handleClickOgaApp = () => {
    window.location.href = 'http://197.210.166.58:4006/#/oga-app';
  };

  const handleClickSecureMe = () => {
    window.location.href = 'https://vitelwireless.net/secureme-app';
  };

  // cLBS PRODUCTION
  // 1) https://vitelwireless.net/secureme-app --  secureme
  // 2) https://vitelwireless.net/oga-app -- oga-app

  const handleClickVerifyJobs = () => {
    window.location.href = 'http://www.verifiedjobs.info';
  };

  return (
    <>
      <div>
        <div className="moreADDed">
          <div className="fetchValueText">More Vitel Wireless Value Added Services </div>
          <div className="saveLIne"></div>
        </div>
        <main className="page-content">
          <a className="d_card" href="https://www.intellims.com/oga-app" target="blank">
            <div className="content_Vas">
              <h2 className="heading">Oga App</h2>
              <p className="data-content">Is a mobile app and web tool built to improve honesty at the workplace..</p>
            </div>
          </a>
          <a className="d_card" href="">
            <div className="content_Vas">
              <h2 className="heading">Oga-Boiz</h2>
              <p className="data-content">
                Is used by employees as companion tool to Oga-App. After creating your Oga-App account, you will need to
                require your staff to download Oga-Boiz for clock-in/clock-out.{' '}
              </p>
            </div>
          </a>
          <a className="d_card" href="https://www.intellims.com/secure-me" target="blank">
            <div className="content_Vas">
              <h2 className="heading">SecureMe</h2>
              <p className="data-content">
                SecureMe was built to help keep us all safer by tracking and recording our movement only when activated
                by the individual.
              </p>
            </div>
          </a>
          <a className="d_card" href="https://www.verifiedjobs.info" target="blank">
            <div className="content_Vas">
              <h2 className="heading">Verify-jobs</h2>
              <p className="data-content">
                Is built to increase the level of Truthfulness when employing a new domestic or professional staff.
              </p>
            </div>
          </a>
        </main>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};
export default ViewMoreVas;
