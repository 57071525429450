import { useNavigate } from "react-router-dom"
import "./dataBundle.css"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { vitelWirelessAxios } from "../../../../utility/axios";
import { useState } from "react";

const DataBundle = (() => {


   const [subscriberInfo, setSubscriberInfo] = useState({});
   const [allowRecharge, setAllowRecharge] = useState(false);
   const [airTimeError, setAirtimeErro] = useState(false);
   const [pending, setPending] = useState(false);
   const navigator = useNavigate();
    
     
     const UserNumber = localStorage.getItem('MSISDN');
 
   console.log('UserNumber>>>>', UserNumber);
  
   const formik = useFormik({
     initialValues: {
       MSISDN: UserNumber,
       amount: '',
     },
     validationSchema: Yup.object({
       MSISDN: Yup.string()
         .matches(/^[0-9]+$/, 'MSISDN must contain only numbers')
         .required('MSISDN is required'),
       amount: Yup.string()
         .matches(/^[0-9]+$/, 'amount must contain only numbers')
         .required('amount is required'),
     }),
 
     onSubmit: async values => {
       setPending(true)
       const formattedMSISDN = values.MSISDN.replace(/^0/, '234');
       const MSISDN = {
         MSISDN: formattedMSISDN,
       };
       console.log('res MSISDN>>>>', MSISDN);
       await vitelWirelessAxios
         .post('/generals/getSubscriberData', MSISDN)
         .then(res => {
           console.log('res', res);
           setPending(false)
           if (res.data.message == 'Customer MSISDN Found') {
             setSubscriberInfo(res.data.customerData);
             setAllowRecharge(true);
             handleTopUpCredit(res.data.customerData);
             console.log('res', res);
           } else if (res.data.message !== 'Customer MSISDN Found') {
             setAirtimeErro(true);
             console.log('fail to load');
           } else {
             // notifySuccess(res, values);
           }
         })
         .catch(err => console.log(' Login unsuccessfull', err));
         setPending(false)
       // setLoader(false)
     },
   });
 
   const handleTopUpCredit = subscriberInfo => {
     navigator('/air-time-top-up', {
       state: {
         amount: formik.values.amount,
         MSISDN: formik.values.MSISDN,
         subscriberInfo: subscriberInfo,
       },
     });
   };


return (

        <>
            <div className="toBUyBundle">To Buy a bundle , click any of the options below</div>
            <div className="cards">
                <div className="card" >
                     <div className="setCArdsFlex">
                     <span>Day & Night Bundle</span>
                        <span> 200MB</span>
                     </div>
                     <div className="setCArdsFlex">
                     <span>1 Day</span>
                        <span> NGN 100</span>
                     </div>
                     {/* <button>dddd</button> */}
                  
                </div>
                <div className="card">
                     <div className="setCArdsFlex">
                     <span>1GB Daily</span>
                        <span> 1GB</span>
                     </div>
                     <div className="setCArdsFlex">
                     <span>1 Day</span>
                        <span> NGN 200</span>
                     </div>
                  
                </div>
                <div className="card">
                     <div className="setCArdsFlex">
                     <span>Night Bundle</span>
                        <span> 200MB</span>
                     </div>
                     <div className="setCArdsFlex">
                     <span>2 Days</span>
                        <span> NGN 100</span>
                     </div>
                  
                </div>
                <div className="card">
                     <div className="setCArdsFlex">
                     <span>All Social Bundles</span>
                        <span> 500MB</span>
                     </div>
                     <div className="setCArdsFlex">
                     <span>3 Days</span>
                        <span> NGN 300</span>
                     </div>
                  
                </div>
                {/* <div className="card"></div>
                <div className="card"></div>
                <div className="card"></div> */}
            </div>
        </>
    )
})
export default DataBundle