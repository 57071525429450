import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import './QuickTopupSuccess.css';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import html2pdf from 'html2pdf.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoMdShare } from 'react-icons/io';
import { GiSaveArrow } from 'react-icons/gi';
import vw from '../../../images/redesignLogo2.jpg';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { vitelWirelessAxios } from '../../../utility/axios';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

const QuickTopSuccess = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const currentDate = moment().format('MMM Do, YYYY HH:mm:ss A');
  const [userNumber, setUserNumber] = useState("");
  const [sendUrl, setSendUrl] = useState("");
  const [PdfFiles, setPdfFiles] = useState("")

  const { amount, MSISDN, subscriberInfo, paystackPaymentReference } = location.state || {};

  useEffect(() => {
    document.title = amount ? `Vitel Wireless | ${amount} Airtime top up success` : 'Airtime top up success';
  }, [amount]);
  //   console.log('topupinfo', topupinfo);

  const generatePDF = () => {
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: `VW_${paystackPaymentReference}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    const content = document.getElementById('pdfContent');

  const pfff =  html2pdf().set(options).from(content).save();
  setPdfFiles(pfff)
  };

  const generateAndSharePDF = async () => {
    const element = document.getElementById('pdfContent');
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: `VW_${paystackPaymentReference}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    try {
      // Generate PDF as Blob
      const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');

      // Convert Blob to File
      const pdfFile = new File([pdfBlob], `VW_${paystackPaymentReference}.pdf`, {
        type: 'application/pdf',
      });

      // Check if Web Share API is supported
      if (navigation.canShare && navigation.canShare({ files: [pdfFile] })) {
        // Use Web Share API
        await navigation.share({
          title: `VW_${paystackPaymentReference}.pdf`,
          text: 'Check out this PDF file!',
          files: [pdfFile], // Pass the file for sharing
        });

        // console.log('Shared successfully');
      } else {
        // Fallback for unsupported browsers
        alert('Sharing not supported on this browser; the file will be downloaded automatically');
      }
    } catch (error) {
      console.error('Error generating or sharing the PDF:', error);
    }
  };

  const handleBackToHome = () => {
    navigation('/');
  };

  // const handleGetUserNumber = ((e)=>{
  //   const num = e.target.value;
    
  //    })
    
  // const sendWhatsAppMessage = async (message) => {
   
  //   const params = {
  //   phone: userNumber,
  //   text: message,
  //   };
    
  //   try {
  //   const response = await vitelWirelessAxios.post(url, { params });
  //   console.log('Message sent:', response.data);
  //   } catch (error) {
  //   console.error('Error sending message:', error);
  //   }
  //   };
  // const generatePDFWhatsApp = async () => {
  //   const element = document.getElementById('pdfContent'); // Your receipt element ID
  //   const canvas = await html2canvas(element);
  //   const imgData = canvas.toDataURL('image/png');
  
  //   // Generate PDF
  //   const pdf = new jsPDF();
  //   pdf.addImage(imgData, 'PNG', 10, 10, 190, 0); // Fit to page
  //   pdf.save('receipt.pdf');
  // };


  // const sendToWhatsApp = async (fileUrl) => {
  //   const accountSid = 'YOUR_TWILIO_ACCOUNT_SID';
  //   const authToken = 'YOUR_TWILIO_AUTH_TOKEN';
  //   const client = require('twilio')(accountSid, authToken);
  
  //   await client.messages.create({
  //     from: 'whatsapp:+14155238886', // Twilio Sandbox WhatsApp number
  //     to: `whatsapp:${userNumber}`, // Recipient's WhatsApp number
  //     body: 'Here is your receipt:',
  //     mediaUrl: [fileUrl], // URL of the generated file
  //   });
  
  //   console.log('Message sent to WhatsApp!');
  // };


  const handleSubmit =() => {
    // Generate the receipt PDF
       const url = `https://api.whatsapp.com/send/?phone=+234${userNumber}&text=${generatePDF()}`;
    window.open(url, '_blank');
    setSendUrl(url)
  
  };


  return (
    <>
    <div className="parentTopupreceipt">
      <div className="topupreceiptdiv" >

        <div id="pdfContent">
          <div className="recieptFirstItem">
          <div className="network">
            <img src={vw} alt="" />
          </div>
          <h3>
            ₦
            {parseInt(amount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h3>
          <span className="topupstatus">
            <IoMdCheckmarkCircle className="topupIcon" /> Airtime top up successful
          </span>
          <div className="transBonusdiv">
            <span>Bonus Earned</span> <span>₦0</span>
          </div>
        </div>

        <div className="topuptransacdetial">
          <h4>Transaction Details</h4>
          <div className="parenttransacDiv">
            <div className="transdiv">
              <span>Recipient Mobile</span> <span>{MSISDN.replace(/^234/, '0')}</span>
            </div>
            <div className="transdiv">
              <span>Recipient Name</span>{' '}
              <span>
                {subscriberInfo.lastName} {subscriberInfo.firstName}
              </span>
            </div>
            <div className="transdiv">
              <span>Transaction Type</span> <span>Airtime top up</span>
            </div>

            <div className="transdiv">
              <span>Transaction Ref.</span> <span>{paystackPaymentReference}</span>
            </div>
            <div className="transdiv">
              <span>Transaction Date</span> <span>{currentDate} </span>
            </div>
          </div>
        </div>
        </div>
        <div>
      {/* <Form>
      <Form.Group className="mb-1" controlId="formBasic">
        <Form.Label>Would you like a copy of this receipt sent to your WhatsApp? Please enter your number </Form.Label>
        <Form.Control type="number" placeholder="Enter WhatsAPP Number" onChange={(e)=>{setUserNumber(e.target.value)}}/>
        <Form.Text className="text-muted">
          We'll never share your number with anyone else.
        </Form.Text>
      </Form.Group>

      <a className="getBackBtn-bt" onClick={handleSubmit}>
        Submit
      </a>
    </Form> */}
        </div>

        <div className="backToHome">
          <div className="saveIconDiv" onClick={generatePDF}>
            <span className="shareIconspan">
              <GiSaveArrow className="saveIcon" />
            </span>
            <span>Download Receipt</span>
          </div>
          <div className="shareIconDiv saveIconDiv2" onClick={generateAndSharePDF}>
            <span className="shareIconspan">
              <IoMdShare className="saveIcon" />
            </span>
            <span>Share Receipt</span>
          </div>

          <button className="getBackBtn" onClick={handleBackToHome}>
            Back To Home
          </button>
        </div>
      </div>
    
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    
    </>
  );
};

export default QuickTopSuccess;
