import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Faq from './components/layouts/faq';
import ReserveANumber from './components/layouts/reserve-number/reserve-a-number';
import LearnMore from './components/layouts/learnmore';
import ReservedNumberCart from './components/layouts/reserve-number/reserved-number-cart';
import HomePage from './components/HomePageComponents/HomePage';
import Footer from './components/layouts/FooterComponent/footer';
import Login from './components/layouts/Login/Login';
import NavBar from './components/layouts/NavBarComponent/NavBar';
import Devices from './components/layouts/DeviceSection/Devices';
import DataPage from './components/layouts/DataComponent/DataPage';
import BottomMenu from './components/layouts/bottomMenuComponent/bottomMenu';
import PlanPurchase from './components/layouts/PlanPurchase/PlanPurchase';
import Ecommerce from './components/layouts/EcommerceComponent/e-commerce';
import ShoppingCart from './components/layouts/EcommerceComponent/shoppingCart';
import ProductDetailsPage from './components/layouts/ProductDetailsPage/ProductDetailsPage';
import DashBoard from './components/layouts/SubscriberDashboard/DashBoard';
import AirTimeTopConfirm from './components/layouts/AirTimeTopConfirm/AirTimeTopConfirm';
import ProductPurchaseConfirm from './components/layouts/AirTimeTopConfirm/productPurchaseConfirm/ProductPurchaseConfirm';
import DataPlans from './components/layouts/DataPlans/DataPlans';
import SocialMediaBundlesPage from './components/layouts/SocialMediaBundles/SocialMediaBundlesPage/SocialMediaBundlesPage';
import EcomCategories from './components/layouts/EcommerceComponent/categories';
import DeliveryPage from './components/layouts/DeiveryComponent/deliveryPage';
import Page404 from './components/layouts/404Page/Page404';
import DefaultView from './components/layouts/SubscriberDashboard/DashBoardContent/DefaultViewComponent/DefaultView';
import UserQuickTopUp from './components/layouts/SubscriberDashboard/DashBoardContent/UserQuickTopUp/UserQuickTopUp';
import TransactionHistory from './components/layouts/SubscriberDashboard/DashBoardContent/TransactionHistory/TransactionHistory';
import Notifications from './components/layouts/SubscriberDashboard/DashBoardContent/Notifications/Notifications';
import MyWishList from './components/layouts/SubscriberDashboard/DashBoardContent/MyWishList/MyWishList';
import MobileSubscriberProfile from './components/layouts/SubscriberDashboard/DashBoardContent/SubscriberProfile/MobileSubscriberProfile';
import ProtectedRoute from './components/utility/ProtectedRoute';
import CoperateProduct from './components/layouts/CoperateProduct/coperateProduct';
import QuickTopSuccess from './components/layouts/AirTimeTopConfirm/QuickTopupSuccess/QuickTopSuccess';
import BecomePartner from './components/layouts/BecomePartner/BecomePartner';
import FormPage from './components/layouts/corporateForm/formPage';
import PartnerForm from './components/layouts/BecomePartner/PartnerForm/PartnerForm';
import FamilySafety from './components/layouts/FamilySafetyPage/familySafety';
import SupportPage from './components/layouts/SupportPage/SupportPage';
import FaqPage from './components/layouts/faqPage/faqPage';
import AutoLogout from './components/utility/AutoLogout';
import FindStore from './components/layouts/FindStore/FindStore';
import ScrollToTop from './components/utility/ScrolltoTop';
import ViewMoreVas from './components/layouts/ViewMoreVas/moreVas';
import SubscribersWallet from './components/layouts/SubscriberDashboard/DashBoardContent/Wallet/wallet';
import WalletStatementent from './components/layouts/SubscriberDashboard/DashBoardContent/Wallet/WalletStatement/walletStatement';
import DataBundle from './components/layouts/SubscriberDashboard/DashBoardContent/DataBundle/dataBundle';
import PrivacyPolicy from './components/layouts/PrivacyPolicy/privacyPolicy';
import TermsAndCondition from './components/layouts/TermsAndCondition/termsAndCondition';
import AppWallet from './components/layouts/AppWallet/appWallet';


function App() {
  return (
    <>
      <Router>
        <NavBar />
        <ScrollToTop />
        <Routes basename="/">
          <Route path="/" element={<HomePage />} />
          <Route path="/plan-purchase/:id/:productName" element={<PlanPurchase />} />
          <Route path="/product-details/:id/:productName" element={<ProductDetailsPage />} />
          <Route path="/air-time-top-up" element={<AirTimeTopConfirm />} />
          <Route path="/data/data-plans" element={<DataPlans />} />
          <Route path="/data/social-media-bundles" element={<SocialMediaBundlesPage />} />
          <Route path="/purchase-confirmation/:orderId" element={<ProductPurchaseConfirm />} />
          <Route path="/quick-topup-confirmation" element={<QuickTopSuccess />} />
          <Route path="/become-vitel-partner" element={<BecomePartner />} />
          <Route path="/vitel-wireless-support" element={<SupportPage />} />
          <Route path="/become-vitel-partner/partner-request-form" element={<PartnerForm />} />
          <Route path="/customer/account/subscriber"element={<ProtectedRoute><DashBoard /></ProtectedRoute>}>
            <Route path="" element={<DefaultView />} />
            <Route path="quick-top-up/:id/:userId" element={<UserQuickTopUp />} />
            <Route path="profile/:id/:userId" element={<MobileSubscriberProfile />} />
            <Route path="transaction-history/:id/:userId" element={<TransactionHistory />} />
            <Route path="wish-list/:id/:userId" element={<MyWishList />} />
            <Route path="subscribers-wallet/:id/:userId" element={<SubscribersWallet />} />
            <Route path="wallet-transaction-statements/:id/:userId" element={<WalletStatementent />} />
            <Route path="notification/:id/:userId" element={<Notifications />} />
            <Route path="data-bundle/:id/:userId" element={<DataBundle />} />
           
          </Route>
          <Route path="/business-solutions-request" element={<FormPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/app-wallet" element={<AppWallet />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsAndCondition />} />
          <Route path="/reserve-number" element={<ReserveANumber />} />
          <Route path="/learn-more" element={<LearnMore />} />
          <Route path="/reservedNumberCart" element={<ReservedNumberCart />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/data-plan" element={<DataPage />} />
          <Route path="/more-vas-products" element={<ViewMoreVas />} />
          <Route path="/subscriber-login" element={<Login />} />
          <Route path="/about-us" element={<BottomMenu />} />
          <Route path="/our-services" element={<BottomMenu />} />
          <Route path="/our-partners" element={<BottomMenu />} />
          <Route path="/e-commerce" element={<Ecommerce />} />
          <Route path="/shopping-cart" element={<ShoppingCart />} />
          <Route path="/categories" element={<EcomCategories />} />
          <Route path="/delivery-pickup" element={<DeliveryPage />} />
          <Route path="/corperate-products" element={<CoperateProduct />} />
          <Route path="/family-safety" element={<FamilySafety />} />
          <Route path="/find-vitel-store" element={<FindStore />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
