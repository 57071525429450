import HomeNavComponent from '../home-nav-component';

import logo from '../../images/vw-removebg-preview.png';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import './reserve-number.css';
import './reserveNum.css';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import LearnMoreNav from '../learnmore-nav';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Footer from '../FooterComponent/footer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import secureme from '../../images/secureme.jpg';
import ogaboiz from '../../images/ogaapp.jpg';
import ogabos from '../../images/ogaboiz.jpg';

import useFectch from '../../utility/getFunction';
import { getCartTotal, increase, decrease, remove, clearCart, getCartItems } from '../../redux/counter';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { vitelWirelessAxios } from '../../utility/axios';
import cartImage from '../../images/SHOP.png';
import { PaystackButton } from 'react-paystack';
import Alert from 'react-bootstrap/Alert';
import NavBar from '../NavBarComponent/NavBar';

import vw from '../../images/redesignLogo2.jpg';

import { RiLoginCircleFill } from 'react-icons/ri';
import { FaWhatsappSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { markItemUnavailable } from '../../redux/counter';

let renderCount = 0;

const ReservedNumberCart = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm();
  renderCount++;

  const [lgaId, setLgaId] = useState('');
  const [pendingUpload, setPendingUpload] = useState(false);
  const [refNumber, setRefNumber] = useState();
  const [localGvt, setLocalGvt] = useState([]);
  const [state, setState] = useState('');
  const [displayEmptyCart, setDisplayEmptyCart] = useState('');
  const [show, setShow] = useState(false);
  const [physicalSim, setPhysicalSim] = useState('');
  const [eSim, setEsim] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [disable, setDisable] = useState(true);
  const [returnData, setReturnData] = useState('');
  const [failedAlert, setFailedAlert] = useState(false);
  const [hideAlert, setHideAlert] = useState(true);
  const [productLoad, setProductLoad] = useState([]);

  const [dataNumber, setDataNumber] = useState([]);
  const [isPendingDataNumber, setIsPendingDataNumber] = useState(true);
  const [errorDataNumber, setErrorDataNumber] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [allDatas, setAllDatas] = useState('');
  const [checkingData, setCheckingData] = useState('');

  // payment base url

  // const paymentUrl = 'http://10.0.5.51:3020/api/v1/';
  const paymentUrl = process.env.REACT_APP_BASE_URL;

  // console.log("paymentUrl", paymentUrl);

  useEffect(() => {
    document.title = 'Vitel Wireless | Reverse Number Cart';
  }, []);

  const handlePhysicalSim = () => {
    setPhysicalSim('physicalSim');
  };

  const handleEsim = () => {
    setEsim('eSim');
  };

  const handleAllDataCheck = data => {
    console.log('data>>>>>>', data);
  };

  // currency format//
  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');
  //

  const {
    data: numbers,
    isPending: numbersIsPending,
    error: numbersError,
  } = useFectch('/registrations/getUploadNumbers');

  // const update = () => {
  //   vitelWirelessAxios.get("/registrations/getUploadNumbers")
  //       .then((res) => {
  //         setProductLoad(res.data);
  //       });
  //   };
  //   useEffect(update, [productLoad]);

  const handleClearCart = () => {
    dispatch(clearCart());
    handleClose();
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/reserve-number');
  };
  const dispatch = useDispatch();
  // const {
  //   data: numbers,
  //   isPending: numbersIsPending,
  //   error: numbersError
  // } = useFectch('registrations/getUploadNumbers');

  const { items, hasError } = useSelector(state => state.cart);

  const numbersIdItem = items.map(phone => phone.phoneNumberId);

  useEffect(() => {
    setIsPendingDataNumber(true);
    vitelWirelessAxios
      .get('/registrations/getUploadNumbers')
      .then(response => {
        if (!response.statusText === 'OK') {
          throw Error('Could not fetch data');
        } else {
          setIsPendingDataNumber(false);
        }
        setIsPendingDataNumber(false);
        setDataNumber(response.data);
        setErrorDataNumber(null);
        dataNumber.map(item => item.phoneNumberId);

        console.log('availableItemIds>>', dataNumber);

        items.forEach(cartItem => {
          if (!dataNumber && dataNumber.includes(cartItem.phoneNumberId)) {
            console.log(`Removing item with ID: ${cartItem.phoneNumberId}`);
          } else {
            console.log(`Item with ID: ${cartItem.phoneNumberId} is available`);
          }
        });
      })
      .catch(error => {
        setErrorDataNumber(error.message);
        setIsPendingDataNumber(true);
        console.log(error);
      });
  }, [items]);

  const { data: states, isPending: statesIsPending, error: statesError } = useFectch('generals/states');
  const {
    data: localGvtCouncil,
    isPending: localGvtIsPending,
    error: localGvtError,
  } = useFectch('generals/local_govt');

  const { data: area, isPending: areaIsPending, error: areaError } = useFectch(`generals/getPickupCenter/${lgaId}`);

  const totalPrice = items.reduce((acc, item) => {
    return acc + Number(item.price) * item.quantity;
  }, 0);

  const [vat, setVat] = useState(0);
  const [totalWithVAT, setTotalWithVAT] = useState(0);

  useEffect(() => {
    const vat = totalPrice * 0.075;
    setVat(vat);
    const total = totalPrice + vat;
    setTotalWithVAT(total);
  }, [totalWithVAT, vat, totalPrice]);

  const onChangeEmail = e => {
    setEmailAddress(e.target.value);
  };

  const onChangeHandler = e => {
    console.log('email', e.target.value);
    const newStateId = e.target.value;
    setState(newStateId);

    const filterLga = localGvtCouncil?.filter(lga => lga.stateId === newStateId);
    setLocalGvt(filterLga);
  };

  const handleLocalGvt = e => {
    const lgaIdGet = e.target.value;
    setLgaId(lgaIdGet);
  };

  // console.log('localId', lgaId);

  useEffect(() => {
    if (items?.length === 0) {
      setDisplayEmptyCart(true);
    } else {
      setDisplayEmptyCart(false);
    }
  }, []);

  const handleHideAlert = () => setHideAlert(false);

  const handleSubmitData = async data => {
    setEmailAddress(data?.email);
    setPendingUpload(true);
    const allData = {
      ...data,
      simType: eSim || 'physicalSim',
      totalPrice: totalWithVAT,
      vat: vat,
      state: state,
      lga: lgaId,
      vitelNumbers: items,
    };

    console.log('show sendinging data>>>', allData);
    await vitelWirelessAxios
      .post('/registrations/createReserveNumber', allData)
      .then(res => {
        setShow(true);
        setReturnData(res.data);
        console.log('res', res);
        setPendingUpload(false);
      })
      .catch(err => {
        console.log('err', err);
        setPendingUpload(false);
        setFailedAlert(true);
      });
  };
  const [showCheck, setShowCheck] = useState('');
  const handleCloseCheck = () => setShowCheck(false);

  const handleShowCheck = async data => {
    setEmailAddress(data?.email);
    setPendingUpload(true);

    setShowCheck(true);
    const allData = {
      ...data,
      simType: eSim || 'physicalSim',
      totalPrice: totalWithVAT,
      vat: vat,
      state: state,
      lga: lgaId,
      vitelNumbers: items,
    };
    setAllDatas(allData);

    console.log('avaaaaa', items);
    await vitelWirelessAxios
      .post('/generals/checkAvailablePhoneNumbers', { vitelNumbers: items })
      .then(res => {
        // setCheckingData(res.data);
        console.log('Items>>>>', res.data);
        setPendingUpload(false);
        setCheckingData(res.data);
      })
      .catch(err => {
        console.log('err', err);
        setPendingUpload(false);
      });
  };

  const isSubmitDisabled =
    checkingData.MSISDNValidationArr && checkingData.MSISDNValidationArr.some(item => item.success == false);

  const handleSubmitDataPayment = data => {
    setEmailAddress(data?.email);
    setPendingUpload(true);
    setShowCheck(false);
    const handler = window.PaystackPop.setup({
      key: process.env.REACT_APP_PAYSTACK_KEY,
      email: emailAddress,
      amount: totalWithVAT * 100,
      currency: 'NGN',
      // ref: '' + Math.floor(Math.random() * 1000000000 + 1),

      callback: function (response) {
        setReferenceCode(response.reference);
        // console.log('Transaction successful. Reference: ' + response.reference);
        // const allData = {
        //   ...data,
        //   simType: eSim || 'physicalSim',
        //   totalPrice: totalWithVAT,
        //   vat: vat,
        //   state: state,
        //   lga: lgaId,
        //   vitelNumbers: items,
        //   paystackPaymentReference: response.reference
        //   };

        // console.log('Transaction>>>>>>' + allData );

        fetch(`${paymentUrl}/registrations/createReserveNumber`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(allDatas, referenceCode),
        })
          .then(res => res.json())
          .then(data => {
            setShow(true);
            setReturnData(data);
            setPendingUpload(false);
            console.log('Payment and form data saved successfully:', data);
          })
          .catch(error => {
            console.error('Error saving payment and form data:', error);
            setFailedAlert(true);
            setPendingUpload(false);
          });
      },
      onClose: function () {
        // setFailedAlert(true);
        setPendingUpload(false);
      },
    });

    handler.openIframe();
  };

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLoginClick = () => {
    localStorage.setItem('isLoggedIn', 'true');
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, [isLoggedIn]);

  const handleBactToHome = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDataPlanPage = () => {
    navigate('/data-plan');
  };

  return (
    <>
      {/* <LearnMoreNav /> */}

      <div className=" setHeaderCart">
        <div>
          <div className="setBAckgroud">{/* <h3 className="vitelHeaderB"> Vitel Wireless Order Review </h3> */}</div>

          {items?.length === 0 ? (
            <div className=" emptyCartDIv">
              <div>
                {' '}
                <img src={cartImage} className="image-Empty" />{' '}
              </div>
              <h2>NO Vitel Wireless Number in Your Cart</h2>

              <button className="button-65" role="button" onClick={handleNavigate}>
                Go pick a number
              </button>

              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          ) : (
            <div className=" container-Cart">
              <div className="container-Cart form-reserve">
                <div className="backFOrmcart">
                  <h3 className="vitelHeaderB"> Vitel Wireless Order Review </h3>
                  <div>
                    <p className="youHavemade">
                      You have made some great selections. Complete the form below and you are set to go!
                    </p>
                  </div>
                </div>
                <div className="grid">
                  {totalPrice === 0 ? (
                    <form
                      action=""
                      onSubmit={handleSubmit((data, event) => {
                        // event.target.reset()
                        console.log('seedata', data);
                        handleSubmitData(data);
                      })}
                    >
                      <fieldset>
                        <legend>How do you want it?</legend>
                        <div class="form__group">
                          <input
                            type="radio"
                            checked
                            id="berries_1"
                            className="input"
                            name="now"
                            onClick={() => setPhysicalSim('physicalSim')}
                          />
                          <label for="berries_1" className="berries_2">
                            Physical SIM Card
                          </label>
                        </div>
                        <div class="form__group">
                          <input
                            type="radio"
                            id="berries_2"
                            className="input"
                            name="now"
                            onClick={() => setEsim('eSim')}
                          />
                          <label for="berries_2" className="berriespie">
                            eSIM (select only if your phone supports eSIM)
                          </label>
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>Select your state and area so we know which center will process your order.</legend>
                        <div className="setDivided">
                          <div className="setDivPayInput">
                            <label className="labelText">
                              Select State <span className="red-text">*</span>{' '}
                            </label>
                            <select className=" selectmain classic22" onChange={onChangeHandler}>
                              <option disabled>Choose...</option>
                              {states.map(state => (
                                <option value={state.stateId}> {state.name} </option>
                              ))}

                              {statesIsPending && <option>Loading...</option>}
                            </select>
                            <br />
                          </div>

                          <div className="setDivPayInput">
                            <label className="labelText">Local Government </label>
                            <select className=" selectmain  classic22" onChange={handleLocalGvt}>
                              <option disabled>Choose...</option>
                              {localGvt &&
                                localGvt.map((lga, i) => (
                                  <option key={i.id} value={lga.lgaId} name="townOfResidence">
                                    {' '}
                                    {lga.name}
                                  </option>
                                ))}
                              {localGvtIsPending && <option>Loading...</option>}
                            </select>
                            <br />
                          </div>

                          <div className="setDivPayInput">
                            <label className="labelText">Select Area </label>

                            <select className=" selectmain  classic22" {...register('area')}>
                              {/* <option disabled>Choose...</option> */}

                              {area && area.map(a => <option value={a.pickupArea}>{a.pickupArea}</option>)}
                              {area.length === 0 && !areaIsPending && (
                                <option disabled> No available center here yet </option>
                              )}
                              {/* {areaIsPending && <option>Loading...</option>} */}
                            </select>
                            <br />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="down-fielDset">
                        <legend>We need email and phone number to contact you when delivering your order.</legend>
                        <Form>
                          <Row className="breakColumDiv">
                            <Col>
                              <label>
                                Email <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="Email"
                                type="email"
                                {...register('email', {
                                  required: 'Email is required',
                                  maxLength: {},
                                })}
                              />
                              <span className="cum-error">{errors.email?.message}</span>
                            </Col>
                            <Col>
                              <label>
                                Phone Number <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="Phone number"
                                type="tel"
                                {...register('phone', {
                                  required: 'Phone Number is required',
                                  maxLength: {
                                    value: 11,
                                    message: 'Phone Number must not be more than eleven digit',
                                  },
                                })}
                              />
                              <span className="cum-error">{errors.phone?.message}</span>
                            </Col>
                          </Row>
                          <Row className="breakColumDiv">
                            <Col>
                              <label>
                                First Name <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="First name"
                                {...register('firstName', {
                                  required: 'First name is required',
                                  maxLength: {
                                    message: 'First name is required',
                                  },
                                })}
                              />
                              <span className="cum-error">{errors.firstName?.message}</span>
                            </Col>
                            <Col>
                              <label>
                                Last Name <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="Last name"
                                {...register('lastName', {
                                  required: 'Last name is required',
                                  maxLength: {
                                    message: 'Last name is required',
                                  },
                                })}
                              />
                              <span className="cum-error">{errors.lastName?.message}</span>
                            </Col>
                          </Row>
                        </Form>
                      </fieldset>
                      {/* <button type="submit">submit</button> */}

                      <div className="  quizDown">
                        <div className="quiz-window-header">
                          <div className="quiz-window-title">YOUR ORDER </div>
                        </div>
                        {/* 
                          <div className="quiz-window-body tableCARt" >



                            <table className="table ">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">S/N</th>
                                  <th scope="col">Vitel Number</th>
                                  <th scope="col">Premium</th>
                                  <th scope="col">Price</th>
                                  <th scope="col"></th>
                                </tr>

                              </thead>
                              <tbody>
                                {
                                  items && items.map((num, i) => (

                                    <tr key={num.phoneNumberId}>
                                      <td >{i + 1}</td>
                                      <th scope="row">{num.MSISDN} </th>
                                      <td >{num.Primum}</td>
                                      <td>{symbol}{num.price}</td>
                                      <td>  <a href="#!" className="text-danger ccdelete"><i className="fas fa-lg" onClick={() => dispatch(remove(num.phoneNumberId))}> <FaRegTrashAlt size={25} /></i></a></td>

                                    </tr>

                                  ))
                                }


                              </tbody>



                            </table>
                            </div> */}

                        <div className="quiz-window-body">
                          <table className="table tablus">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Vitel Number</th>
                                <th scope="col">Premium</th>
                                <th scope="col">Price</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {items &&
                                items.map((num, i) => (
                                  <tr key={num.phoneNumberId}>
                                    <td>{i + 1}</td>
                                    <th scope="row">
                                      {num.MSISDN}
                                      {/* {num.isAvailable === false && <span className='avaiRed'>( Not available)</span>}    */}
                                    </th>
                                    <td>{num.Premium}</td>
                                    <td>
                                      {symbol}
                                      {num.price}
                                    </td>
                                    <td>
                                      {' '}
                                      <a href="#!" className="text-danger ccdelete">
                                        <i className="fas fa-lg" onClick={() => dispatch(remove(num.phoneNumberId))}>
                                          {' '}
                                          <FaRegTrashAlt size={25} />
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>

                          <ul className="guiz-awards-row guiz-awards-row-even">
                            <div className="yepspan">
                              <span>Total:</span>
                              <span className="amountSt">
                                {symbol}
                                {totalPrice}
                              </span>
                            </div>
                            <div>
                              <span>VAT (7.5%):</span>
                              <span className="amountSt">
                                {symbol} {vat}
                              </span>
                            </div>
                            <div>
                              <span>Amount to Pay:</span>
                              <span className="amountSt">
                                {symbol}
                                {totalWithVAT}
                              </span>
                            </div>
                          </ul>

                          <div className="guiz-awards-buttons">
                            {failedAlert && (
                              <Alert variant="danger">Cannot process this request please try again later</Alert>
                            )}

                            <div className="justifySubmited">
                              <div variant="primary">
                                {pendingUpload ? (
                                  <button className="guiz-awards-but-back2">
                                    <i className="fa fa-angle-left"></i>
                                    <span class="loaderButton"></span>{' '}
                                  </button>
                                ) : (
                                  <button className="guiz-awards-but-back2" type="submit">
                                    <i className="fa fa-angle-left"></i>Click to Reserve{' '}
                                  </button>
                                )}
                              </div>
                              <div>
                                {' '}
                                <button className="guiz-awards-but-back" onClick={handleNavigate}>
                                  Return to Selection
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form
                      action=""
                      onSubmit={handleSubmit((data, event) => {
                        // event.target.reset()
                        console.log('seedata', data);
                        handleShowCheck(data);
                      })}
                    >
                      <fieldset>
                        <legend>How do you want it?</legend>
                        <div class="form__group">
                          <input
                            type="radio"
                            checked
                            id="berries_1"
                            className="input"
                            name="now"
                            onClick={() => setPhysicalSim('physicalSim')}
                          />
                          <label for="berries_1">Physical SIM Card</label>
                        </div>
                        <div class="form__group">
                          <input
                            type="radio"
                            id="berries_2"
                            className="input"
                            name="now"
                            onClick={() => setEsim('eSim')}
                          />
                          <label for="berries_2" className="berriespie">
                            eSIM (select only if your phone supports eSIM)
                          </label>
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>Select your state and area so we know which center will process your order.</legend>
                        <div className="setDivided">
                          <div className="setDivPayInput">
                            <label className="labelText">
                              Select State <span className="red-text">*</span>{' '}
                            </label>
                            <select className=" selectmain classic22" onChange={onChangeHandler}>
                              <option disabled>Choose...</option>
                              {states.map(state => (
                                <option value={state.stateId}> {state.name} </option>
                              ))}

                              {statesIsPending && <option>Loading...</option>}
                            </select>
                            <br />
                          </div>

                          <div className="setDivPayInput">
                            <label className="labelText">Local Government </label>
                            <select className=" selectmain  classic22" onChange={handleLocalGvt}>
                              <option disabled>Choose...</option>
                              {localGvt &&
                                localGvt.map((lga, i) => (
                                  <option key={i.id} value={lga.lgaId} name="townOfResidence">
                                    {' '}
                                    {lga.name}
                                  </option>
                                ))}
                              {localGvtIsPending && <option>Loading...</option>}
                            </select>
                            <br />
                          </div>

                          <div className="setDivPayInput">
                            <label className="labelText">Select Area </label>

                            <select className=" selectmain  classic22" {...register('area')}>
                              {/* <option disabled>Choose...</option> */}

                              {area && area.map(a => <option value={a.pickupArea}>{a.pickupArea}</option>)}
                              {area.length === 0 && !areaIsPending && (
                                <option disabled> No available center here yet </option>
                              )}
                              {/* {areaIsPending && <option>Loading...</option>} */}
                            </select>
                            <br />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="down-fielDset">
                        <legend>We need email and phone number to contact you when delivering your order.</legend>
                        <Form>
                          <Row className="breakColumDiv">
                            <Col>
                              <label>
                                Email <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="Email"
                                type="email"
                                {...register('email', {
                                  required: 'Email is required',
                                  maxLength: {},
                                })}
                              />
                              <span className="cum-error">{errors.email?.message}</span>
                            </Col>
                            <Col>
                              <label>
                                Phone Number <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="Phone number"
                                type="tel"
                                {...register('phone', {
                                  required: 'Phone Number is required',
                                  maxLength: {
                                    value: 15,
                                    message: 'Phone Number must not be more than eleven digit',
                                  },
                                })}
                              />
                              <span className="cum-error">{errors.phone?.message}</span>
                            </Col>
                          </Row>
                          <br className="hideMM" />
                          <Row className="breakColumDiv">
                            <Col>
                              <label>
                                First Name <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="First name"
                                {...register('firstName', {
                                  required: 'First name is required',
                                  maxLength: {
                                    message: 'First name is required',
                                  },
                                })}
                              />
                              <span className="cum-error">{errors.firstName?.message}</span>
                            </Col>
                            <Col>
                              <label>
                                Last Name <span className="red-text">*</span>
                              </label>
                              <Form.Control
                                placeholder="Last name"
                                {...register('lastName', {
                                  required: 'Last name is required',
                                  maxLength: {
                                    message: 'Last name is required',
                                  },
                                })}
                              />
                              <span className="cum-error">{errors.lastName?.message}</span>
                            </Col>
                          </Row>
                        </Form>
                      </fieldset>
                      {/* <button type="submit">submit</button> */}

                      <div className=" container-Car quizDown">
                        <div className="quiz-window-header">
                          <div className="quiz-window-title">YOUR ORDER </div>
                        </div>

                        {/* <div className="quiz-window-body tableCARt">



                            <table className="table">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">S/N</th>
                                  <th scope="col">Vitel Number</th>
                                  <th scope="col">Premium</th>
                                  <th scope="col">Price</th>
                                  <th scope="col"></th>
                                </tr>

                              </thead>
                              <tbody>
                                {
                                  items && items.map((num, i) => (

                                    <tr key={num.phoneNumberId}>
                                      <td >{i + 1}</td>
                                      <th scope="row">{num.MSISDN} </th>
                                      <td >{num.Primum}</td>
                                      <td>{symbol}{num.price}</td>
                                      <td>  <a href="#!" className="text-danger ccdelete"><i className="fas fa-lg" onClick={() => dispatch(remove(num.phoneNumberId))}> <FaRegTrashAlt size={25} /></i></a></td>

                                    </tr>

                                  ))
                                }


                              </tbody>
                     </table>
                     </div> */}

                        <div className="quiz-window-body">
                          <table className="tabl tablus">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Vitel Number</th>
                                <th scope="col">Premium</th>
                                <th scope="col">Price</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {items &&
                                items.map((num, i) => (
                                  <tr key={num.phoneNumberId}>
                                    <td>{i + 1}</td>
                                    <th scope="row">
                                      {num.MSISDN}
                                      {/* {num.isAvailable === false && <span className='avaiRed'> Not available</span>}   */}
                                    </th>
                                    <td>{num.Premium}</td>
                                    <td>
                                      {symbol}
                                      {num.price}
                                    </td>
                                    <td>
                                      {' '}
                                      <a href="#!" className="text-danger ccdelete">
                                        <i className="fas fa-lg" onClick={() => dispatch(remove(num.phoneNumberId))}>
                                          {' '}
                                          <FaRegTrashAlt size={25} />
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>

                          <ul className="guiz-awards-row guiz-awards-row-even">
                            <div className="yepspan">
                              <span>Total:</span>
                              <span className="amountSt">
                                {symbol}
                                {totalPrice}
                              </span>
                            </div>
                            <div>
                              <span>VAT (7.5%):</span>
                              <span className="amountSt">
                                {symbol}
                                {vat}{' '}
                              </span>
                            </div>
                            <div>
                              <span>Amount to Pay:</span>
                              <span className="amountSt">
                                {symbol}
                                {totalWithVAT}
                              </span>
                            </div>
                          </ul>

                          <div className="guiz-awards-buttons">
                            {failedAlert && (
                              <Alert variant="danger">Cannot process this request please try again later</Alert>
                            )}

                            <div className="justifySubmited">
                                <div variant="primary">
                                {pendingUpload ? (
                                  <button className="guiz-awards-but-back2">
                                    <i className=""></i>
                                    <span class="loaderButton"></span>{' '}
                                  </button>
                                ) : (
                                  <button
                                    className="guiz-awards-but-back2"
                                    type="submit"
                                    // onClick={()=>handleShowCheck}
                                  >
                                    <i></i>Preview{' '}
                                  </button>
                                )}
                              </div>
                              <div>
                                {' '}
                                <button className="guiz-awards-but-back" onClick={handleNavigate}>
                                  Return to Selection
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Modal show={showCheck} onHide={handleCloseCheck} backdrop="static" size="lg" keyboard={false}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="ajustNmae">
                            <div>
                              {' '}
                              <h5>
                                {' '}
                                <span className="ajustNmaellabel">First Name:</span> {allDatas.firstName}{' '}
                              </h5>{' '}
                            </div>
                            <div>
                              {' '}
                              <h5>
                                {' '}
                                <span className="ajustNmaellabel">Last Name:</span> {allDatas.lastName}{' '}
                              </h5>{' '}
                            </div>
                          </div>
                          <div className="ajustNmae">
                            <div>
                              {' '}
                              <h5>
                                {' '}
                                <span className="ajustNmaellabel"> Phone:</span> {allDatas.phone}{' '}
                              </h5>{' '}
                            </div>
                            <div>
                              {' '}
                              <h5>
                                {' '}
                                <span className="ajustNmaellabel">Email:</span> {allDatas.email}{' '}
                              </h5>{' '}
                            </div>
                          </div>

                          <div className="ajustNmae">
                            <div className="cenTERsimtype">
                              {' '}
                              <h5>
                                {' '}
                                <span className="ajustNmaellabel">Sim Type:</span> {allDatas.simType}{' '}
                              </h5>{' '}
                            </div>
                          </div>

                          <h5 className="aling-reModal"> You are about to reserve the following number(s)</h5>
                          <table className="table">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Vitel Number</th>
                                <th scope="col">Price</th>
                                <th scope="col">Availability</th>

                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {pendingUpload && <span class="loaderButton"></span>}

                              {checkingData.MSISDNValidationArr &&
                                checkingData.MSISDNValidationArr.map((num, i) => (
                                  <tr key={num.phoneNumberId}>
                                    <td>{i + 1}</td>
                                    <th scope="row">
                                      {num.MSISDN}
                                      {/* {num.isAvailable === false && <span className='avaiRed'> Not available</span>}   */}
                                    </th>
                                    <td>
                                      {symbol}
                                      {num.price}
                                    </td>
                                    {num.success === true ? (
                                      <td style={{ color: 'green' }}>Available</td>
                                    ) : (
                                      <td style={{ color: 'red' }}>Not Available</td>
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <div> Note: Please go and pick another number if the selected number is "Not Available" </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleCloseCheck}>
                            Close
                          </Button>
                          <Button
                            className="guiz-awards-but-back2"
                            type="submit"
                            disabled={isSubmitDisabled}
                            onClick={() => handleSubmitDataPayment()}
                          >
                            Proceed Payment
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </form>
                  )}
                </div>
              </div>
              <br />

              <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                {/* <div className="parentContainer">
                  <div className="lowerNavEle">
                    <div className="companyLogoDiv">
                      <img src={vw} alt="" />
                    </div>
                    <div className="navItemDiv navData">
                      <ul>
                        <li className="navRight">Data</li>
                        <li className="navRight">Devices</li>
                        <li className="navRight">Voice Plan</li>
                        <li className="navRight">Support</li>
                        <li className="navRight">SIM Service</li>
                      </ul>
                      <ul className="search">
                        <li>
                          <Link to="/subscriber-login" className="Loginlink">
                            {isLoggedIn ? 'Login' : 'Logout'}
                            <RiLoginCircleFill />{' '}
                          </Link>
                        </li>
                        <li className="supportDiv">
                          <FaWhatsappSquare className="whatsappIcon" />
                          <div className="supportNum">
                            <span className="justNumber">07077011111</span> 24/7 support
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="harmburgerDiv">
                      <span onClick={toggleMenu}>{isMenuOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}</span>
                    </div>

                    {isMenuOpen && (
                      <div className="MobileNavItemDiv">
                        <ul>
                          <li>Data</li>
                          <li>Devices</li>
                          <li>Voice Plan</li>
                          <li>Support</li>
                          <li>SIM Service</li>
                        </ul>
                        <ul className="search">
                          <li>
                            <Link to="/subscriber-login" className="Loginlink">
                              {isLoggedIn ? 'Logout' : 'Login'}
                              <RiLoginCircleFill />{' '}
                            </Link>
                          </li>
                          <li className="supportDiv">
                            <FaWhatsappSquare className="whatsappIcon" />
                            <div className="supportNum">
                              <span className="justNumber">07077011111</span> 24/7 support
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div> */}

                <Modal.Header>
                  <Modal.Title className="checkSuccess">
                    {' '}
                    <Alert variant="success" className="sceModal" show={hideAlert}>
                      {' '}
                      {returnData.message}{' '}
                      <span className="ytkLEft" onClick={handleHideAlert}>
                        x
                      </span>{' '}
                    </Alert>{' '}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="setTexts">
                    {' '}
                    It is great that you have joined the new way of doing things. Welcome to{' '}
                    <span style={{ fontWeight: 'bold', color: '#0A074E' }}>Vitel Wireless</span>. You are just a tap
                    away if you need anything, check out the FAQ or contact Support.
                  </p>

                  <div>
                    <p className="setTexts">
                      {' '}
                      Your Customer Code is: <span className="setTextsNum">{returnData.customerCode}</span>{' '}
                    </p>

                    <p className="setTexts">
                      {' '}
                      Your order reference number is: <span className="setTextsNum">
                        {returnData.ReferenceNumber}
                      </span>{' '}
                    </p>
                    <p className="setTexts">
                      {' '}
                      For the following number(s):
                      {returnData.resevedNumbers &&
                        returnData.resevedNumbers.map(num => (
                          <ul>
                            <li style={{ fontWeight: 'bold' }}> {num.MSISDN} </li>
                          </ul>
                        ))}
                    </p>
                    <p className="setTexts" style={{ color: '#0A074E', fontWeight: 'bold' }}>
                      {' '}
                      Note: Use your camera to snap and save it.
                    </p>
                  </div>

                  <div>
                    <p className="setTexts">
                      Would you like to create an only account and start using some of our features that are already
                      available on smartphones. The following features are already available
                    </p>
                  </div>

                  <div className="setlogolo">
                    <div className="ogaSecure card">
                      <a href="https://play.google.com/store/apps/details?id=com.intellims.secureme&pcampaignid=web_share">
                        {' '}
                        <img src={secureme} className="secureImage" />
                      </a>

                      <p>Join us in shaping a safer world with SecureMe</p>
                    </div>
                    <div className="ogaSecure card">
                      <a href="https://play.google.com/store/apps/details?id=com.intellims.ogaapp&pcampaignid=web_share">
                        {' '}
                        <img src={ogaboiz} className="secureImage" />
                      </a>
                      <p>LBS/GPS-based clock-in system that automates the recording of your work hours.</p>
                    </div>
                    <div className="ogaSecure card">
                      <a href="https://play.google.com/store/apps/details?id=com.intellims.ogaboiz&pcampaignid=web_share">
                        {' '}
                        <img src={ogabos} className="secureImage" />
                      </a>

                      <p>LBS/GPS-based clock-in management system </p>
                    </div>
                  </div>
                  <div>
                    You can join any of our trending communities, to improve your hustle. Click on any below.
                    <ul className="listhair">
                      <li>Crypomanias</li>
                      <li>Japa go where!</li>
                      <li>Hairstylist vs. Boutique</li>
                    </ul>
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Modal.Body>
                <Footer />
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClearCart}>
                    Close
                  </Button>
                  {/* <Button variant="primary">Understood</Button> */}
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      {/* <div className="footerSet">
       <Footer/>
      </div> */}
    </>
  );
};

export default ReservedNumberCart;
