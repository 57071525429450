import NavBar from "../NavBarComponent/NavBar"
import SubscribersWallet from "../SubscriberDashboard/DashBoardContent/Wallet/wallet";
import "./appWallet.css";



const AppWallet = (()=>{



    return(
<>
<NavBar/>
<div className="mainPush-wallet wrapper">

<SubscribersWallet/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
</div>


</>
)
})

export default AppWallet