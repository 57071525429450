import moment from "moment"
import { vitelWirelessAxios } from "../../../../../utility/axios";
import { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";


const WalletStatementent = (() => {


    const userNumberDisplay = localStorage.getItem('MSISDN');
    const subscribInfoRaw = localStorage.getItem('subscriberInformation');
    const subscribInfoProcessed = JSON.parse(subscribInfoRaw);
    const [referenceCode, setReferenceCode] = useState('');
    const [amount, setAmount] = useState("");
    const [refreshPending, setRefreshPending] = useState(false);
    const [showStateMent, setShowStateMent] = useState(false);

    const [walletTopUpPending, setWalletTopUpPending] = useState(false)
    const [walletDisplayData, setWalletDisplayData] = useState("");
    const [success, setSuccess] = useState(false);



    const [show, setShow] = useState(false);
    const [showTopupModal, setTopupModel] = useState(false);

    const [showAmount, setShowAmount] = useState(false);
    const [allWalletData, setAllWalletData] = useState("");
    const [walletDatum, setWalletDatum] = useState("");

    const options = { maximumFractionDigits: 2 }

    const symbol = Intl.NumberFormat('en-US', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: 'NGN'
    })
        .formatToParts(0)
        .filter(part => part.type === 'currency')
        .map(part => part.value)
        .join('')



    const handleShowAmount = (() => {
        setShowAmount(!showAmount)
    })
    const handleShowTopupModal = () => {
        setTopupModel(true)
    }
    const handleHideTopupModal = () => {

        setTopupModel(false)
    }

    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const getData = {
            userId: subscribInfoProcessed.customerId,
            phoneNumber: userNumberDisplay
        }

        setIsPending(true)
        vitelWirelessAxios.post('/payments/getWalletLedgerData', getData)
            .then(response => {

                if (!response.statusText === "OK") {
                    throw Error("Could not fetch data")
                } else {
                    setIsPending(false)
                }
                setIsPending(false)
                setWalletDatum(response.data)
                // setData(response.data)

                setError(null)
                // console.log("data>>>>>>>>>",data)

            })
            .catch(error => {
                setError(error.message)
                setIsPending(false)
                console.log(error)
            })
    }, []);




    useEffect(() => {
        setAllWalletData(allWalletData)
    }, [allWalletData])


    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setShow(true);
        setWalletDisplayData(data)
        console.log("showData>>>>>>", data)

    }

    const handleAmount = (e) => {
        setAmount(e.target.value)
    }

    console.log("walletDatum>>>>>>>", walletDatum)

    const walletDatas = walletDatum.walletData

    const CustomerLedger = walletDatum.ledgerRecords;



    console.log("CustomerLedger>>>", CustomerLedger)



    useEffect(() => {

        let timeout;
        if (success) {
            timeout = setTimeout(() => {
                setSuccess(false);
            }, 5000)
        }
        return () => {
            clearTimeout(timeout);

        };

    }, [success])




    const handleGoback = () => {
        setShowStateMent(false)
    }

    const checkColorPayment = (description) => {
        switch (description) {
            case "credit":
                return "green";
            case "debit":
                return "red";
            default:
        }
    };

    return (
        <>

            <div>
                <div onClick={handleGoback} className="fullSetBack">  <MdOutlineKeyboardBackspace size={40} /> </div>

                <div className="recenttranStext">Transaction History</div>
                <table className="tableInerSet">

                    <thead>
                        <tr>
                            <th scope="col">Trans Type</th>
                            <th scope="col">Balance Before</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Current Balance</th>
                            <th scope="col">Date</th>
                            <th scope="col">Paid for</th>
                            <th scope="col">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            CustomerLedger && CustomerLedger.map((data) => (
                                <tr key={data.walletLedgerId}>
                                    <td data-label="Trans Type" ><span style={{ color: `${checkColorPayment(data.type)}`, fontWeight: "bold" }}>  {data.type}</span></td>
                                    <td data-label="Balance Before" >{symbol}{Intl.NumberFormat("en-US", options).format(data.preBalance)} </td>
                                    <td data-label="Amount">{symbol}{Intl.NumberFormat("en-US", options).format(data.amount)} </td>
                                    <td data-label="Current Balance">{symbol}{Intl.NumberFormat("en-US", options).format(data.currentBalance)} </td>
                                    <td data-label="Date">
                                        {moment(data.createdDateTime).format('lll')}
                                    </td>
                                    <td data-label="Status"
                                    //  className="comSample"
                                    >{data.paidFor}</td>
                                    <td data-label="Details"> <IoMdEye size={30} onClick={() => handleShow(data)} /> </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>


        </>
    )
})

export default WalletStatementent