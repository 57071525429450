import { Link, useNavigate } from "react-router-dom";
import "./vasSection.css";


const  VasSection = (()=>{

  const navigate = useNavigate();

    const handleClickOgaApp = () => {
        window.location.href = 'https://www.intellims.com/oga-app';
        };


        const handleClickSecureMe = ()=>{

            window.location.href = "https://www.intellims.com/secure-me"
        }

        // cLBS PRODUCTION
        // 1) https://vitelwireless.net/secureme-app --  secureme 
        // 2) https://vitelwireless.net/oga-app -- oga-app

        const handleClickVerifyJobs = ()=>{

            window.location.href = "http://www.verifiedjobs.info"
        }

        const navigateViewMoreVas = ()=>{
          navigate("/more-vas-products")
        }
        
     
    return(
         <>
       

<section className="dd_heading_all">

      
  <div className="dd_heading">
  <h2 className="text-center" style={{color:"white"}}>Value Added Services</h2>
{/* <h3 className="text-center">Designed by : <strong>Dhruval Desai</strong></h3> */}
</div>
<main className="page-content">
   <a className="d_card"  href="https://www.intellims.com/oga-app" target="blank">
  <div className="content_Vas">
        
        <h2 className="heading">Oga App</h2>
        <p className="data-content">Is a mobile app and web tool built to improve honesty at the workplace..
    </p>
      </div>
   
  </a>
  <a className="d_card" href="" >
    
    <div className="content_Vas">
      <h2 className="heading">Oga-Boiz</h2>
      <p className="data-content">Is used by employees as companion tool to Oga-App. After creating your Oga-App account, 
        you will need to require your staff to download Oga-Boiz for clock-in/clock-out.  </p>
    </div>
  </a>
  <a className="d_card" href="https://www.intellims.com/secure-me" target="blank">
    <div className="content_Vas">
      <h2 className="heading">SecureMe</h2>
      <p className="data-content">
           SecureMe was built to help keep us all safer by tracking and recording 
           our movement only when activated by the individual.
           </p>
    </div>
  </a>
  <a className="d_card" href="http://www.verifiedjobs.info" target="blank">
    <div className="content_Vas">
      <h2 className="heading">Verify-jobs</h2>
      <p className="data-content">
      Is built to increase the level of Truthfulness when employing a new domestic or professional 
      staff. 
        </p>
    </div>
  </a>
  <a className="learDropLearny" onClick={navigateViewMoreVas} >View More</a>
</main>

</section>
        
        
        </>
    )
})

export default VasSection